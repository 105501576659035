<template>
  <div class="price">
    <div class="wrap2">
      <div v-show="type === 0">
        <h2 style="margin-top: 50px">コース</h2>
        <p style="text-align: center;font-size: 14px;color:white;margin-bottom: 30px;line-height: 2;letter-spacing: 3px;">
          新鮮な食材の仕入れ価格の上昇に伴い、一部メニューの値上げを実施しました。
          <br>
          何卒ご理解のほどお願い申し上げます。
        </p>
        <img
          class="maru"
          :src="`${rootUrl}/img/takumi-hirano_pa001.png`"
          alt="くも"
        />
      </div>
      <div class="course">
        <div v-if="type === 0">
          <p class="price">16,500円</p>
          <p class="price">19,800円</p>
          <p class="price">22,000円</p>
          <p class="price">大将おまかせコース</p>

          <div class="special_menu">
            <img
              class="kakko"
              :src="`${rootUrl}/img/course/kakko_01.webp`"
              alt="かっこ"
            />
            <div class="text_box">
              <p class="text">その日の厳選食材を用いた大将おまかせの特別コースです。</p>
              <p class="text">おまかせはもちろん、事前にご希望の食材や料理をリクエストいただくことも可能です。</p>
              <p class="text">※ 価格は当日の厳選食材に応じて変動いたします。</p>
            </div>
            <img
              class="kakko"
              :src="`${rootUrl}/img/course/kakko_02.webp`"
              alt="かっこ閉じる"
            />
          </div>
        </div>
        <div v-else-if="type === 1">
            <p class="price">4,320円</p>
            <p class="price">5,400円</p>
            <p class="note">お祝い弁当、ちらし寿司もあります。詳しくはお問い合わせください。</p>
        </div>

        <p class="note" v-html="description"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Uiprice",
  components: {},
  props: {
    description: String,
    type: Number,
  },
  data() {
    return {};
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.price {
  text-align: center;
  .wrap2{
    width: 100%;
    margin :0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .maru {
    width: 30px;
    line-height: 10px;
  }
  .course {

    .price {
      font-size: 20px;
      line-height: 60px;
    }

    .special_menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .kakko {
        width: 10px;
        @media screen and (max-width: 767px) {
          width: 23px;
        }
        @media screen and (max-width: 375px) {
          width: 28px;
        }
      }
      .text_box {
        padding: 0 20px;
        @media screen and (max-width: 375px) {
          padding: 0;
        }
        .text {
          font-size: 14px;
          line-height: 25px;
          letter-spacing: 3px;
        }
      }
    }

    .note {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: white;
      margin-top: 50px;
      margin-bottom: 7px;
      @media screen and (max-width: 767px) {
        letter-spacing: 1px;
        line-height: 25px;
        margin-top: 30px;
      }
    }
  }
}
</style>
